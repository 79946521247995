import React from 'react'
import dynamic from 'next/dynamic'

const ContentBlock = dynamic(() => import('./elements/content-block'))
const EntryGrid = dynamic(() => import('./elements/entry-grid'))
const FeatureGrid = dynamic(() => import('./elements/feature-grid'))
const TeaserList = dynamic(() => import('./elements/teaser-list'))
const EntrySlider = dynamic(() => import('./elements/entry-slider'))
const Freeform = dynamic(() => import('./elements/freeform'))
const Hero = dynamic(() => import('./elements/hero'))
const MockElement = dynamic(() => import('./elements/mock-element'))
const DynamicForm = dynamic(() => import('./elements/dynamic-form'))
const ContactCard = dynamic(() => import('./elements/contact-card'))
const Testimonials = dynamic(() => import('./elements/testimonials'))
const VideoPlayer = dynamic(() => import('./elements/video-player'))
const HeaderImage = dynamic(() => import('./elements/header-image'))
const Stats = dynamic(() => import('./elements/stats'))
const PositionHighlight = dynamic(() => import('./elements/position-highlight'))
const JobSurvey = dynamic(() => import('./elements/job-survey/job-survey'))
const CallToAction = dynamic(() => import('./elements/call-to-action'))
const JobSearch = dynamic(() => import('./elements/job-search'))
const ContentFAQ = dynamic(() => import('./elements/content-faq'))

export const componentProvider = (props) => {
  switch (props.type) {
    case 'hero':
      return <Hero {...props} />
    case 'headerImage':
      return <HeaderImage {...props} />
    case 'stats':
      return <Stats {...props} />
    case 'grid':
      return <EntryGrid {...props} />
    case 'slider':
      return <EntrySlider {...props} />
    case 'freeform':
      return <Freeform {...props} />
    case 'contentFaq':
      return <ContentFAQ {...props} />
    case 'contentBlock':
      return <ContentBlock {...props} />
    case 'contactCard':
      return <ContactCard {...props} />
    case 'testimonials':
      return <Testimonials {...props} />
    case 'videoPlayer':
      return <VideoPlayer {...props} />
    case 'featureGrid':
      return <FeatureGrid {...props} />
    case 'positionHighlight':
      return <PositionHighlight {...props} />
    case 'formElement':
      return <DynamicForm {...props} />
    case 'teaserList':
      return <TeaserList {...props} />
    case 'jobSurvey':
      return <JobSurvey {...props} />
    case 'callToAction':
      return <CallToAction {...props} />
    case 'jobSearch':
      return <JobSearch {...props} />
    default:
      return <MockElement {...props} />
  }
}
