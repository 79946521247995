import React, { FC } from 'react'
import { PageProps } from '../../types/site.interface'
import { componentProvider } from '../component-provider'
import EntryContent from '../entry-content'

export interface PageTypeProps {
  page: PageProps
}

const Page: FC<PageTypeProps> = ({ page }) => {
  return <EntryContent elements={page.content} componentProvider={componentProvider} />
}

export default Page
