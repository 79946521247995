import React, { FC, ReactElement } from 'react'
import { ContentElementProps } from '@/types/site.interface'
import logger from '@ignition/library/lib/logger'
import MockElement from './elements/mock-element'

type ContentComponentProvider = (element: ContentElementProps) => ReactElement

interface Props {
  elements: ContentElementProps[]
  componentProvider?: ContentComponentProvider
  className?: string
}

const mockComponentProvider = (props) => <MockElement {...props} />

const EntryContent: FC<Props> = ({ className, elements, componentProvider = mockComponentProvider }) => {
  return (
    <div className={className}>
      {elements?.map((element) => {
        const component = componentProvider(element) || null

        if (!component) {
          logger.error('PageContent: element rendered as null', element)
        }

        return component
      })}
    </div>
  )
}

export default EntryContent
